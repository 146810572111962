@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100..900&display=swap");
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 191 66% 58%;
    --primary-dark: 191 55% 53%;
    --primary-foreground: 220 86% 97%;
    --secondary: 38 100% 48%;
    --secondary-foreground: 220 86% 97%;
    --gray-dark: #0c1521;
    --gray-light: #35404d;
    --muted: 38 100% 48%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 38 100% 48%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 220 86% 97%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 191 66% 58%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 220 86% 97%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 220 86% 97%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 220 86% 97%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 220 86% 97%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 220 86% 97%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 220 86% 97%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 220 86% 97%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
  }
}

html {
  padding: 0 !important;
}
html,
body {
  @apply font-vazirmatn !important;
}

:dir(rtl) select {
  background-position: left 0.5rem center;
}

* {
  font-family: "Vazirmatn", system-ui;
}

.images-slider {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  width: 100% !important;
  height: 100% !important;
}
.images-slider img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(0.5);
}

.hero {
  /* background-image: linear-gradient(
      90deg,
      rgba(30, 30, 30, 0.8),
      rgba(30, 30, 30, 0)
    ),
    url(../../public/hero.jpg); */
}

.about {
  background-image: url(../../public/about-lines.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
}

.lg-container {
  @apply w-full min-[1400px]:w-[85vw] px-6 lg:px-[40px] min-[1400px]:px-0 mx-auto;
}
.base-container {
  @apply max-w-[1400px] w-full px-6 lg:px-[40px] mx-auto;
}
.card-shadow {
  box-shadow: 0px 0px 60px 0px rgba(229, 233, 237, 0.6);
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.contact-form {
  box-shadow: 0px 0px 80px 0px rgba(30, 42, 57, 0.06);
}

.PhoneInput {
  @apply h-[58px] border border-[#E4E6EC] rounded-[10px] sm:rounded-lg focus:border-gray-400 inline-block placeholder:text-dark-1 placeholder:text-opacity-60 focus:outline-none focus:ring-0 disabled:bg-gray-100 disabled:opacity-80 py-3 sm:py-[16px] px-4 sm:px-6 w-full;
}

.PhoneInputInput {
  border: none !important;
  outline: none !important;
}

.PhoneInputInput:focus {
  @apply ring-offset-0 ring-0;
}

.PhoneInputInput::placeholder {
  color: #b9bdc2;
}

@media (max-width: 1024px) {
  .contact-form {
    box-shadow: none;
  }
}
@media (min-width: 786px) {
  .md\:card-shadow {
    box-shadow: 0px 0px 60px 0px rgba(229, 233, 237, 0.6);
  }
}
.btn-contained-secondary {
  @apply text-white border-transparent bg-secondary hover:bg-secondary-dark disabled:bg-secondary;
}
.btn-outlined-secondary {
  @apply border-secondary text-secondary hover:bg-secondary disabled:bg-white hover:text-white disabled:text-secondary;
}
.btn-contained-sky-blue {
  @apply border-transparent bg-sky-blue hover:bg-sky-blue-dark disabled:bg-sky-blue text-dark-2;
}
.btn-contained-blue {
  @apply border-transparent bg-alt-blue disabled:bg-sky-blue text-white;
}
.btn-outlined-sky-blue {
  @apply border-sky-blue text-sky-blue hover:bg-sky-blue disabled:bg-white hover:text-white disabled:text-sky-blue;
}
.btn-contained-danger {
  @apply text-white bg-red-500 border-transparent hover:bg-red-600 disabled:bg-red-500;
}
.btn-contained-primary {
  @apply text-white border-transparent bg-primary hover:bg-primary-dark disabled:bg-primary;
}
.btn-outlined-danger {
  @apply text-red-500 border-red-500 hover:bg-red-500 disabled:bg-white hover:text-white disabled:text-red-500;
}

.truncate-line-one {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate-4-lines {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-2-lines {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ql-toolbar.ql-snow {
  @apply rounded-t-2xl;
}
.ql-container.ql-snow {
  @apply rounded-b-2xl h-52;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: #e4e6ec;
}
.ql-snow .ql-picker-options .ql-picker-item:first-child {
  display: none;
}

.blog-post.prose h2 br,
.blog-post.prose h3 br {
  display: none;
}

@media (max-width: 920px) {
  .blog-post.prose h1 {
    font-size: 2.2em;
  }
}

@media (max-width: 640px) {
  .blog-post.prose h1 {
    font-size: 1.9em;
  }
  .about {
    background-image: none;
  }
}

.email-verification__input-container > div {
  @apply gap-3 sm:gap-5 lg:gap-6;
}

.admin-layout #tidio-chat {
  display: none !important;
}

/* .resume {
} */
.resume-bg {
  background-color: #50c4e0;
  background-image: linear-gradient(135deg, #50c4e0 0%, #3bafc3 100%);
}
.resume [data-placeholder]:empty:before {
  content: attr(data-placeholder);
}
.resume [contenteditable="true"] {
  cursor: text !important;
  @apply transition duration-200 ease-in rounded-md outline-none focus:bg-gray-200 hover:bg-gray-200;
}

.resume__hover-block {
  @apply transition duration-200 rounded-md outline-dashed outline-2 outline-offset-2 outline-transparent hover:outline-gray-300;
}

.resume__section-bullet {
  @apply mt-[9px];
}

.print-resume .resume__section-bullet {
  @apply mt-[18px];
}

.print-resume .resume__personal-detail-icon {
  @apply translate-y-2;
}
.print-resume .resume__header-right {
  @apply transform -translate-y-3;
}
.print-resume .resume__title {
  @apply mb-3.5;
}
.print-resume .resume__skill-bg {
  @apply transform translate-y-[9px];
}
.ck-sticky-panel__content {
  top: 80px !important;
}

.ck-rounded-corners {
  border-radius: 0px 0px 10px 10px !important;
}

.ck.ck-toolbar {
  border-radius: 10px 10px 0px 0px !important;
}

.image-inline.ck-widget,
.image.ck-widget {
  display: block;
  width: 100%;
}
.image-style-align-left,
.image-style-align-right {
  width: auto !important;
  margin: 0 !important;
  max-width: 100%;
}
.image-style-align-left img,
.image-style-align-right img {
  margin-top: 0;
  margin-bottom: 0;
}
.image-style-block-align-right,
.image-style-block-align-left {
  margin: 0 !important;
  max-width: 100% !important;
}
.image-style-align-right {
  float: right;
  margin-left: 20px !important;
}
.image-style-align-left {
  float: left;
  margin-right: 20px !important;
}
.blog-post img:not(.image-style-align-left),
.blog-post img:not(.image-style-align-right) {
  width: 100%;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners {
  height: 400px;
}

.ck.ck-powered-by {
  display: none !important;
}

/* Speedy CSS */
.animated-text-orange {
  --bg-size: 400%;
  --color-one: hsl(15, 85%, 61%);
  --color-two: hsl(40 95% 55%);
  /* font-size: clamp(3rem, 25vmin, 8rem); */
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  /* animation: move-bg 8s infinite linear; */
}

.animated-text-green {
  --bg-size: 400%;
  --color-one: hsl(180, 79%, 48%);
  --color-two: hsl(183, 77%, 34%);
  /* font-size: clamp(3rem, 25vmin, 8rem); */
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  /* animation: move-bg 8s infinite linear; */
}

@keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  /* .animated-text-orange,
  .animated-text-green {
    animation: move-bg 8s linear infinite;
  } */
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

.fade-right {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30px;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  transition: all linear 0.3s;
}

.fade-left {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  transition: all linear 0.3s;
}

.box-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.01);
}

.company-banner {
  background-image: linear-gradient(
      rgba(30, 42, 57, 0.8),
      rgba(30, 42, 57, 0.8)
    ),
    url(../assets/images/company-banner.jpg);
}

.create-profile {
  background-image: url(../assets/images/create-profile.jpg);
}

.company-profile-detail [data-radix-aspect-ratio-wrapper] {
  aspect-ratio: 10 / 2.5;
}

@media (max-width: 768px) {
  .company-profile-detail [data-radix-aspect-ratio-wrapper] {
    aspect-ratio: 10 / 5;
  }
}

html {
  overflow: inherit !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Globals for text / buttons Employer Settings Page*/
.emp-settings-title {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #000 !important;
}

.emp-settings-btns {
  border-radius: 10px !important;
  padding: 16px 24px 16px 24px !important;
  height: 52px !important;
  width: fit-content !important;
}

.emp-settings-text-bold {
  font-weight: 500;
  font-size: 15px;
  color: #000;
}

.emp-settings-text {
  font-weight: 400;
  font-size: 15px;
  color: #000;
}

.emp-settings-delete-btn {
  background-color: #ff3b30;
}

.input-icon {
  width: 20px;
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  color: #00000078;
  height: 20px;
}

.input-icon-right {
  width: 20px;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  color: #00000078;
  height: 20px;
}

.link-color a {
  color: hsl(var(--secondary)) !important;
}

@media (min-width: 768px) {
  .job-details-nav-link {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }
}
